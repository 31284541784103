/* src/App.css または src/index.css */

/* 共通のスタイル */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
}

.box {
  background-color: lightblue;
  padding: 20px;
  margin: 10px;
  text-align: center;
  flex: 1 1 200px; /* 最小幅200px、余裕があれば広がる */
}

@media screen and (max-width: 800px) {
  .top-image h1 {
    font-size: 1.5rem;
    font-weight: 200;
    background-color: blue;
    padding: 1rem 2rem;
    border-radius: 5px;
  }

  .container {
    flex-direction: column;
    align-items: center;
  }

  .box {
    flex: 1 1 auto;
    width: 100%;
  }
}

.App-header {
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -20px;
}

.App-header nav {
  color: blue;
  width: 100%;
  display: flex;
  justify-content: center;
}

.App-header nav ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px;
  margin: 0;
  width: 100%;
}

.App-header nav ul li {
  margin:  8px; 
}

.App-header nav ul li img {
  vertical-align: middle;
}

.App-header nav ul li a {
  color: black;
  font-size: 24px;
  text-decoration: none;
}

.App-header nav ul li a:hover {
  color: #007BFF;
}

.top-image {
  background: url('top-image.jpg') no-repeat center center/cover;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.top-image h1 {
  font-size: 3rem;
  font-weight: 800;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem 2rem;
  border-radius: 5px;
}

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo-container img {
  background-color: white;
  width: 80px;
  height: 80px;
  margin-bottom: 10px; /* 画像とテキストの間のスペース */
}

.logo-container p {
  margin: 0;
  font-weight: bold;
  font-size: 30px;
  position: relative;
}

.logo-container p::after {
  content: "";
  display: block;
  width: 100%;
  height: 3px; /* 下線の太さ */
  background-color: skyblue; /* 下線の色 */
  position: absolute;
  bottom: 3px; /* テキストの下からの距離 */
  left: 0;
}

section {
  padding: 1rem 10px;
}

section h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #007BFF;
  display: inline-block;
  padding-bottom: 0.5rem;
}

.content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.content .item {
  background: #f9f9f9;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.content .item img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

table th, table td {
  padding: 0.75rem;
  border: 1px solid #ddd;
}

table th {
  background-color: #f4f4f4;
  text-align: left;
}

.map-iframe {
  margin-top: 20px;
}

/* お問い合わせフォームのスタイル */
form {
  background: #fff;
  padding: 2rem 40px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

form button {
  padding: 0.75rem 1.5rem;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1rem;
}

form button:hover {
  background-color: #0056b3;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin-bottom: 0.5rem;
}

ul li a {
  color: #007BFF;
  text-decoration: none;
  transition: color 0.3s;
}

ul li a:hover {
  text-decoration: underline;
}

strong {
  font-weight: bold;
  color: #333;
}

#contact iframe {
  width: 100%;
  height: 500px;
  border: none;
}

#news li p {
  font-size: 24px;
}

#philosophy p {
  font-size: 24px;
}

/* Additional media queries for better responsiveness */
@media screen and (max-width: 600px) {
  .App-header nav ul {
    flex-wrap: nowrap; /* 改行を防ぐ */
    overflow-x: auto; /* 横スクロールを追加 */
    justify-content: space-around;
    display: flex;
  }

  .App-header nav ul li a {
    font-size: 10px; /* さらに小さく調整 */
  }

  .top-image {
    height: 300px;
  }

  .top-image h1 {
    font-size: 1.5rem; /* 小さく調整 */
  }

  .logo-container p {
    font-size: 24px;
  }

  section h2 {
    font-size: 1.5rem;
  }

  table th, table td {
    padding: 0.5rem;
  }

  form {
    padding: 1rem 20px;
  }

  form button {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }

  #news li p,
  #philosophy p {
    font-size: 18px;
  }
}
